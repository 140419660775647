// popup

var otherProductsChangePlus = false;

$(document).ready(function () {
    /*if ($('.comp_home_top').length > 0) {
        $.ajax({
            data: {do: 'showWelcomePopup'}
        });
    }*/

    if ($('.comp_faq').length > 0) {
        var options = {
            listClass: 'elm_body_inner',
            valueNames: ['part_ui_title', 'part_ui_wsw']
        };
        var listObj = new List('faq-list', options);

        $('#search-field').on('keyup', function () {
            var searchString = $(this).val();
            listObj.search(searchString);
        });

        listObj.on('updated', function () {
            if (listObj.matchingItems.length > 0) {
                $('[data-items]').show();
                $('[data-items-none]').hide();

                var searchedValue = $('#search-field').val();

                $.each(listObj.matchingItems, function (index, item) {
                    highlight($(item.elm).find('.part_ui_title'), searchedValue);
                    highlight($(item.elm).find('.part_ui_wsw'), searchedValue);
                });
            } else {
                $('[data-items]').hide();
                $('[data-items-none]').show();
            }
        });

        function highlight(element, text)
        {
            element.find("span").each(function (index) {
                var text = $(this).text();
                $(this).replaceWith(text);
            });

            var originalText = element.html();

            if (text != '') {
                var reg = new RegExp('(' + text + ')', 'gi');
                var highlightedText = originalText.replace(reg, "<span style='background: yellow'>$1</span>");
                element.html(highlightedText);
            } else {
                element.html(originalText);
            }
        }
    }

    //balíkovna select
    if($('select#balikovna_pobocka').length) {

        function initTippy()
        {
            const template = document.querySelector('#balikovnaOpenHours');
            var initialText;
            if(template !== null && template) {
                initialText = template.textContent;
            } else {
                initialText = '...';
            }

            var tip = tippy('.openingHours', {
                animation: 'shift-toward',
                arrow: true,
                html: '#balikovnaOpenHours',
                onShow(instance) {
                    // `this` inside callbacks refers to the popper element
                    var content = this.querySelector('.tippy-content');

                    var customUrl = $(instance.reference).attr('data-url');

                    if (tip.loading || content.innerHTML !== initialText) return;

                    tip.loading = true;

                    $.ajax({
                        url: customUrl,
                        dataType: 'html'
                    }).done(function(data) {
                        content.innerHTML = data;
                        tip.loading = false
                    });

                },
                onHidden() {
                    const content = this.querySelector('.tippy-content');
                    content.innerHTML = initialText
                },
                // prevent tooltip from displaying over button
                popperOptions: {
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        },
                        hide: {
                            enabled: false
                        }
                    }
                }
            });
        }

        $(window).on('load',function() {
            initTippy();
        });

        function selectTemplate(data, container) {

            if(data.element && data.element.dataset && data.element.dataset.id) {
                var branchId = data.element.dataset.id;
                var city = data.element.dataset.city;
                var cityPart = data.element.dataset.city_part;
                var address = data.element.dataset.address;
                var kind = data.element.dataset.kind;
            } else {
                var branchId = data.id;
                var city = data.city;
                var cityPart = data.city_part;
                var address = data.address;
                var kind = data.kind;
            }

            if (kind === "posta") {
                kind = "pošta"
            }

            if (!branchId) {
                return "";
            } else {
                return $(
                    //"<div class='city'>" + city + "</div>" +
                    "<div class='balikovna-address-content'><div class='cityPart'>" + cityPart + ",</div>" +
                    "<div class='address'>&nbsp;" + address + ",</div>" +
                    "<div class='kind'>&nbsp;" + kind + "</div></div>" +
                    "<div class='openingHours tooltipTrigger icon icon--clock' data-url='?do=balikovnaGetOpenings&bId="+ branchId +"'>" + "" + "</div>"
                );
            }
        }

        var search;

        var balikovnaSelect = $("select#balikovna_pobocka");
        var defaultValueId = balikovnaSelect.attr('data-default-id');

        balikovnaSelect.select2({
            language: "cs",
            ajax: {
                url: "?do=searchBalikovna",
                data: function (params) {
                    search = params.term;
                    return {
                        q: params.term
                    };
                },
                processResults: function (data) {
                    setTimeout(function () {
                        initTippy();
                    }, 300);
                    return {
                        results: $.map(data.branches, function (item) {
                            return {
                                id: item.id,
                                text: item.name,
                                city: item.city,
                                city_part: item.city_part,
                                address: item.address,
                                zip: item.zip,
                                kind: item.kind
                            }
                        })
                    };
                },
                complete: function () {
                    if (search !== undefined) {
                        setTimeout(function () {
                            initTippy();
                        }, 100);
                    }
                }
            },
            templateResult: selectTemplate,
            templateSelection: selectTemplate
        });

        if(defaultValueId) {

            $.ajax({
                url: "?do=searchBalikovna&defaultId=" + defaultValueId,
                dataType: 'json'
            }).done(function(data) {
                if(data.branches && data.branches.length === 1) {

                    var branchData = data.branches[0];

                    var option = new Option(branchData.address, branchData.id, true, true);

                    for (let objKey in branchData) {
                        option.dataset[objKey] = branchData[objKey];
                    }

                    balikovnaSelect.append(option).trigger('change');

                    balikovnaSelect.trigger({
                        type: 'select2:select',
                        params: {
                            data: branchData
                        }
                    });
                }
            });

        }

        balikovnaSelect.on('select2:select',function() {
            setTimeout(function () {
                initTippy();
            }, 100);
        });
    }

    function processViewItemClick(elem,idItem)
    {

        if(elem.closest('.comp_faq').length) {
            if(elem.closest('.elm_item').hasClass('mod--active')) {
                idItem = elem.attr('data-id');
                naja.makeRequest('POST', location.href, {type: 'faq', itemId: idItem, do: 'processItemView'});
            }
        }

        if(elem.closest('.comp_magazine').length) {
            if(!idItem) {
                idItem = elem.attr('data-on-view-magazine');
            }
            naja.makeRequest('POST', location.href, {type: 'magazine', itemId: idItem, do: 'processItemView'});
        }

        if(elem.closest('.comp_articles').length) {
            if(!idItem) {
                idItem = elem.attr('data-on-view-article');
            }
            naja.makeRequest('POST', location.href, {type: 'article', itemId: idItem, do: 'processItemView'});
        }
    }

    var hash = window.location.hash;

    if(hash.match(/^#faq-([0-9]+)$/) || hash.match(/^#magazine-([0-9]+)$/)) {
        var elemToClick = $('[data-trigger-click="' + hash + '"]');
        elemToClick.click();
        processViewItemClick(elemToClick);

        if(hash.match(/^#faq-([0-9]+)$/) && $('[data-trigger-click="' + hash + '"]').length) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('[data-trigger-click="' + hash + '"]').offset().top - 200
            }, 1000);
        }

    }

    $(document).on('click','[data-trigger-click]:not([data-on-view-magazine])',function() {
        processViewItemClick($(this));
    });

    $(document).on('click','[data-on-view-magazine]',function() {
        var idItem = $(this).attr('data-on-view-magazine');
        processViewItemClick($(this),idItem);
    });

    $(document).on('click','.mod--toggled[data-on-view-article]',function() {
        var idItem = $(this).attr('data-on-view-article');
        processViewItemClick($(this),idItem);
    });

    if(hash.match(/^#order-([0-9]+)$/)) {

        setTimeout(function() {
            $('tr[data-order-click="' + hash + '"] .footable-toggle').click();

            $([document.documentElement, document.body]).animate({
                scrollTop: $('tr[data-order-click="' + hash + '"] .footable-toggle').offset().top - 200
            }, 1000);
        },1500);
    }

});

// AJAX FORM
$(document).on('submit', 'form.ajax', function (e) {
    e.preventDefault();
    var $frm = $(this);
    var formData = new FormData($(this)[0]);

    var formSubElem = $(this).find('button');
    formSubElem.attr('disabled','disabled');

    $.ajax({
        method: $frm.attr('method'),
        url: $frm.attr('action'),
        data: formData,
        processData: false,
        contentType: false
    }).done(function() {
        setTimeout(function() {
            formSubElem.removeAttr('disabled');
        },3000);
    });
});

//AJAX LOGIN
$(document).on('submit', 'form.ajax-login', function (e) {
    e.preventDefault();
    var $frm = $(this);
    var formData = new FormData($(this)[0]);
    var fLogin = $('input[name="login"]').val();//formData.get('login') ? formData.get('login').toString() : null;
    var fPassword = $('input[name="password"]').val();//formData.get('password') ? formData.get('password').toString() : null;
    var fRemember = $('input[name="remember"]').prop('checked') === true ? '1' : null;//formData.get('remember') ? formData.get('remember').toString() : null;

    if (typeof window.Capacitor !== 'undefined') {
        if (fRemember === '1') {
            localStorage.setItem('saveLogin', fLogin)
            localStorage.setItem('saveLoginPassword', fPassword)
            localStorage.setItem('saveLoginRemember', fRemember)
        } else {
            localStorage.removeItem('saveLogin')
            localStorage.removeItem('saveLoginPassword')
            localStorage.removeItem('saveLoginRemember')
        }
    }

    if(typeof secureStorage !== 'undefined' && secureStorage) {

        if(fRemember === '1') {
            secureStorage.set(
                function(key) {
                },
                function(error) {
                },
                "gwloginremember",
                fRemember
            );

            secureStorage.set(
                function(key) {
                },
                function(error) {
                },
                "gwlogin",
                fLogin
            );

            secureStorage.set(
                function(key) {
                },
                function(error) {
                },
                "gwpass",
                fPassword
            );
        } else {
            secureStorage.remove(
                function(key) {
                },
                function(error) {
                },
                "gwlogin"
            );

            secureStorage.remove(
                function(key) {
                },
                function(error) {
                },
                "gwpass"
            );

            secureStorage.remove(
                function(key) {
                },
                function(error) {
                },
                "gwloginremember"
            );
        }
    }

    $.ajax({
        method: $frm.attr('method'),
        url: $frm.attr('action'),
        data: formData,
        processData: false,
        contentType: false,
        success: function (payload) {
            if (payload.dialog) {
                nl_lib_dialog.open(payload.dialog, function () {
                    $("body").removeClass("is--loading");
                });
                return;
            }
            if ($frm.filter('[id="snippet-login"]').length) {
                if (payload) {
                    if (payload.redirect) {
                        $("body").addClass("mod--redirect");
                        setTimeout(function () {
                            $("body").removeClass("mod--loaded").addClass("mod--preload");
                            if (payload.redirect == 'this') {
                                location.href = window.location.href.split("#")[0];
                            } else {
                                location.href = payload.redirect;
                            }
                        }, 300);
                    }
                    if (payload.snippets) {
                        for (var i in payload.snippets) {
                            $("#" + i).html(payload.snippets[i]);
                            var $img_default = $("#" + i).find(".mod--default");
                            if ($img_default.length) {
                                $img_default.initial({
                                    charCount: 2,
                                    height: 165,
                                    width: 165
                                });
                            }
                        }
                    }
                }
            }
        }
    });
});

// ALAX LINKS
$(document).on('click', '[href].ajax', function (e) {
    e.preventDefault();
    var $el = $(this);

    if ($el.data('confirm')) {
        if (!confirm($el.data('confirm'))) {
            return;
        }
    }

    $.ajax({
        url: $el.attr('href')
    }).done(function () {
        sr.sync();
    });
});

/* GLOBAL AJAX EVENT HANDLER */
$.ajaxSetup({
    success: function (payload) {
        if (payload) {
            // přesměrování "this" přesměruje na aktuální stránku
            if (payload.redirect) {
                if (payload.redirect == 'this') {
                    location.href = window.location.href.split("#")[0];
                } else {
                    location.href = payload.redirect;
                }
            }

            // výměna snippetů v šabloně - každý snipper musí mít unikátní ID
            if (payload.snippets) {
                for (var i in payload.snippets) {
                    $("#" + i).html(payload.snippets[i]);
                    var $img_default = $("#" + i).find(".mod--default");
                    if ($img_default.length) {
                        $img_default.initial({
                            charCount: 2,
                            height: 165,
                            width: 165
                        });
                    }
                }
            }

            // výměna adresy v adresním řádku
            if (payload.replaceState) {
                window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

                if (payload.replaceState.title) {
                    document.title = payload.replaceState.title;
                }

                if (typeof window.ga === "function") {
                    ga('send', 'pageview', window.location.pathname + window.location.search);
                }
            }

            // dialogy přes newlogic UI
            if (payload.dialog) {
                nl_lib_dialog.open(payload.dialog);
            }

            // automaticke zavreni popup okna
            if ($('[data-autoclose]').length > 0) {
                var time = $('[data-autoclose]').data('autoclose');
                setTimeout(function () {
                    $('[data-dialog-close]').trigger('click');
                }, time);
            }
        }
    },
    error : function(jqXHR, textStatus, errorThrown) {
        if(typeof jqXHR.responseJSON !== 'undefined' && typeof jqXHR.responseJSON.redirect !== 'undefined') {
            if (jqXHR.responseJSON.redirect == 'this') {
                location.href = window.location.href.split("#")[0];
            } else {
                location.href = jqXHR.responseJSON.redirect;
            }
        }

        if (jqXHR.status == 404) {

        } else {

        }
    },
    dataType: "json"
});

$(document).on('click', '[data-append-trigger]', function (e) {
    e.preventDefault();
    var $elm = $(this);
    var $wrapper = $elm.closest('[data-append-wrapper]');
    var categoryId = $elm.data('category-id');
    var page = $elm.data('page');
    var offset = $wrapper.find('[data-append-more]').offset().top;

    $.ajax({
        data: {do: 'getMoreProducts', categoryId: categoryId, page: page}
    }).done(function (payload) {
        $wrapper.find('[data-append-items]').append(payload.html);

        $('html, body').animate({
            scrollTop: offset - (70 + 55)
        }, 800);

        if (payload.hasMore) {
            $wrapper.find('[data-append-trigger]').data('page', page + 1);
        } else {
            $wrapper.find('[data-append-more]').remove();
        }

        sr.sync();
    });
});

// specialni produkty window
$(document).on('click', '[data-ajax-special]', function () {
    naja.makeRequest('GET', location.href, {do: 'showProductsPicker'});

    /*
    $.ajax({
        data: {do: 'showProductsPicker'}
    }).done(function (data) {
        sr.sync();
    });
    */

    return false;
});

// smazani z kosiku
$(document).on('click', '[data-basket-remove]', function (e) {
    e.preventDefault();
    let key = $(this).closest('[data-key]').data('key');
    let autoship = typeof $(this).data('basket-as') !== 'undefined'

    naja.makeRequest('POST', location.href, {do: 'removeProduct', key: key, autoship: autoship});

    /*
    $.ajax({
        method: 'post',
        data: {do: 'removeProduct', key: key}
    });
    */
});

// zmena v kosiku
$(document).on('change', '[data-basket-count]', function () {
    var key = $(this).closest('[data-key]').data('key');
    var val = $(this).val();

    naja.makeRequest('POST', location.href, {do: 'updateProduct', key: key, value: val});

    /*
    $.ajax({
        method: 'post',
        data: {do: 'updateProduct', key: key, value: val}
    });
    */
});

// zmena dopravy
$(document).on('change', '[data-set-shipping]', function (e) {
    checkPayment();

    var shippingCode = $(this).attr('data-code');
    var ajaxShippingUrl = $(this).attr('data-click-ajax');


    naja.makeRequest('POST', location.href, {do: 'setShipping', id: $(this).val()}).then(function() {

        if(shippingCode === 'inpost' && ajaxShippingUrl) {
            naja.makeRequest('POST', ajaxShippingUrl, {},{history: false});
        }

    });

    /*
    $.ajax({
        method: 'post',
        data: {do: 'setShipping', id: $(this).val()}
    });
    */
});

// zmena platby
$(document).on('change', '[data-set-payment]', function (e) {
    checkPayment();

    naja.makeRequest('POST', location.href, {do: 'setPayment', id: $(this).val()});

    /*
    $.ajax({
        method: 'post',
        data: {do: 'setPayment', id: $(this).val()}
    });
    */
});

$(window).on('load', function () {
    sr.sync();
});

var checkPayment = function () {
    $('[data-set-shipping]').prop('disabled', false);
    $('[data-set-payment]').prop('disabled', false);

    var checkedDeliveryInput = $('input[name="shipping"]:checked');
    var checkedPaymentInput = $('input[name="payment"]:checked');

    var checkedDeliveryId = null;
    var checkedPaymentId = null;

    if(checkedDeliveryInput.length) {
        checkedDeliveryId = parseInt(checkedDeliveryInput.val()) || 0;
    }
    if(checkedPaymentInput.length) {
        checkedPaymentId = parseInt(checkedPaymentInput.val()) || 0;
    }

    var availablePaymentIds = [];

    if(checkedDeliveryId) {
        availablePaymentIds = checkedDeliveryInput.attr('data-available-payments').split(',');
    }

    $('[name="payment"]').each(function() {
        if(availablePaymentIds.indexOf($(this).val()) != -1) {

        } else {
            $(this).prop('disabled', true).prop('checked', false);
        }
    });

    // zasilkovna
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'zas') {
        $('.packetery-branch-list').fadeIn(500).parent().slideDown();
    } else {
        $('.packetery-branch-list').fadeOut(500).parent().slideUp();
    }

    //balíkovna
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'bal') {
        $('.elm_select_branches_balikovna').slideDown();
    } else {
        $('.elm_select_branches_balikovna').slideUp();
    }

    //balík na poštu cz/sk
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'bal_post') {
        $('.elm_select_branches_balik_na_postu').slideDown();
    } else {
        $('.elm_select_branches_balik_na_postu').slideUp();
    }

    //sk balíkobox
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'balikobox') {
        $('.elm_select_branches_balikobox').slideDown();
    } else {
        $('.elm_select_branches_balikobox').slideUp();
    }

    //pl inpost
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'inpost') {
        $('.elm_select_branches_inpost').slideDown();
    } else {
        $('.elm_select_branches_inpost').slideUp();
    }

    //ppl parcelshop
    if (checkedDeliveryInput.length && checkedDeliveryInput.attr('data-code').toLowerCase() === 'ppl_parcelshop') {
        $('.elm_select_branches_ppl_parcelshop').slideDown();
    } else {
        $('.elm_select_branches_ppl_parcelshop').slideUp();
    }

};

checkPayment();

$(document).on('click', '[data-dialog-feedback]', function (e) {
    e.preventDefault();
    // html2canvas(document.body, {
    //   onrendered: function (canvas) {
    $.ajax({
        data: {do: 'dialogFeedbackShow'}
    }).done(function () {
        // $('[data-feedback-image]').attr('src', canvas.toDataURL());
    });
    // }
    //});
});

$(document).on('click', '[name="includeImage"]', function () {
    var $elm = $(this);
    var $form = $elm.closest('form');
    var $image = $('[data-feedback-image]');

    if ($elm.prop('checked')) {
        $form.find('[data-feedback-image]').parent().slideDown();
        $form.find('[name="image"]').val($image.attr('src'));
    } else {
        $form.find('[data-feedback-image]').parent().slideUp();
        $form.find('[name="image"]').val('');
    }
});

$(document).on('click', '[data-secondary-add]', function (e) {
    e.preventDefault();
    var $elm = $(this);
    var $product = $elm.closest('[data-id]');

    $.ajax({
        data: {do: 'addSecondaryProduct', productId: $product.data('id'), count: $product.find('[data-secondary-count]').val()}
    }).done(function () {
        // nl_lib_dialog.close();
    });
});

var $timeout_dialog_btn;
$(document).on('change', '[data-product-dialog-add]', function () {
    var $elm = $(this);

    if ($elm.val() > 0) {
        $elm.closest(".part_item_autoship_add").addClass("mod--active").find(".wrp_part_status").addClass("mod--active-state");
    } else {
        $elm.closest(".part_item_autoship_add").removeClass("mod--active").find(".wrp_part_status").removeClass("mod--active-state");
    }

    clearTimeout($timeout_dialog_btn);

    if(typeof otherProductsChangePlus !== 'undefined' && $elm.closest(".part_item_autoship_add").find(".wrp_part_status .elm_info").length === 2) {
        $elm.closest(".part_item_autoship_add").find(".wrp_part_status .elm_info").hide();
        if(otherProductsChangePlus) {
            $elm.closest(".part_item_autoship_add").find(".wrp_part_status .elm_info.product_added").show();
        } else {
            $elm.closest(".part_item_autoship_add").find(".wrp_part_status .elm_info.product_removed").show();
        }
    }

    $elm.closest(".wrp_part_content").find(".wrp_part_status").removeClass("mod--active-remove mod--active");
    $elm.closest(".elm_item_content").find(".wrp_part_status").removeClass("mod--active-remove mod--active").addClass("mod--active");
    $timeout_dialog_btn = setTimeout(function () {
        $elm.closest(".elm_item_content").find(".wrp_part_status").removeClass("mod--active").addClass("mod--active-remove");
        setTimeout(function () {
            $elm.closest(".elm_item_content").find(".wrp_part_status").removeClass("mod--active-remove");
        }, 300);
    }, 1000);

    let autoship = typeof $(this).data('autoship-products') !== 'undefined'
    var $product = $elm.closest('[data-id]');
    if ($elm.val() == 0) {
        naja.makeRequest('GET', location.href, {do: 'removeSecondaryProduct', productId: $product.data('id'), autoship: autoship});

        /*
        $.ajax({
            data: {do: 'removeSecondaryProduct', productId: $product.data('id')}
        });
        */
    } else {
        naja.makeRequest('GET', location.href, {do: 'setSecondaryProduct', productId: $product.data('id'), count: $elm.val(), autoship: autoship});

        /*
        $.ajax({
            data: {do: 'setSecondaryProduct', productId: $product.data('id'), count: $elm.val()}
        });
        */
    }
});

$(document).on('click', '[data-product-dialog-remove]', function () {
    $(this).closest(".part_item_autoship_add").find("[data-product-dialog-add]").val("0").trigger("change")
});

$(document).on('click', '[data-secondary-remove]', function (e) {
    e.preventDefault();
    var $elm = $(this);
    var $product = $elm.closest('[data-id]');

    naja.makeRequest('GET', location.href, {do: 'removeSecondaryProduct', productId: $product.data('id')});

    /*
    $.ajax({
        data: {do: 'removeSecondaryProduct', productId: $product.data('id')}
    });
    */
});

$(document).on('change', '[data-secondary-set]', function () {
    var $elm = $(this);
    var $product = $elm.closest('[data-id]');

    naja.makeRequest('GET', location.href, {do: 'setSecondaryProduct', productId: $product.data('id'), count: $elm.val()});

    /*
    $.ajax({
        data: {do: 'setSecondaryProduct', productId: $product.data('id'), count: $elm.val()}
    });
    */
});

$(document).on('input', '[name="autoship_filter"]', function () {
    var $elm = $(this);

    naja.makeRequest('GET', location.href, {do: 'secondaryProductsPickerFilter', q: $elm.val()});

    /*
    $.ajax({
        data: {do: 'secondaryProductsPickerFilter', q: $elm.val()}
    });
    */
});




